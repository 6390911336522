import { GelButton, useGelFormData } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ReviewClaim = (props: any) => {
  const navigate = useNavigate();
  const { setShowForm, setShowReview, setIsReviewScreen } = props;

  const { onMultiStepFormDataSubmit } = useGelFormData();

  useEffect(() => {
    setIsReviewScreen("Review");
  }, []);

  const backToDashBoard = () => {
    navigate("/");
  };

  const onSubmit = () => {
    setShowForm(false);
    setShowReview(true);
  };

  return (
    <div
      style={{
        paddingTop: getGelTokens().global.sizeBaseX1,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <GelButton secondary medium onClick={backToDashBoard}>
        BACK TO DASHBOARD
      </GelButton>
      <GelButton
        primary
        medium
        onClick={onMultiStepFormDataSubmit(onSubmit)}
        style={{ marginLeft: "auto" }}
      >
        REVIEW CLAIM
      </GelButton>
    </div>
  );
};
