import React, { useState } from 'react';
import {
    GelHeading6,
    GelLabel,
    GelParagraph,
    GelButton,
    GelIcon,
    GelModal,
    GelProgressStatus,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import FileTypes from './FileTypes';
import Uploader from '../../components/layout/Uploader';

interface DeathClaimDocumentsProps {
    lodgement: any;
    setLodgement: (lodgement: any) => any;
    hasNoUploadedFilesError: boolean;
    setHasNoUploadedFilesError: any;
    setIsUploading?: (isUploading: boolean) => void;
}

const DeathClaimDocuments = (props: DeathClaimDocumentsProps) => {

    const {
        lodgement,
        setLodgement,
        setHasNoUploadedFilesError,
        setIsUploading,
    } = props;
    const [openModal, setOpenModal] = useState(false);

    return (
        <div
            style={{
                paddingTop: getGelTokens().global.sizeBaseX4,
            }}
        >
            <GelHeading6>Documents to help us get started</GelHeading6>
            <div style={{ display: "flex" }}>
                <GelParagraph style={{ paddingRight: "10px" }}>
                    Maximum file size: 150 MB per file.
                </GelParagraph>
                <GelButton tertiary small onClick={() => setOpenModal(true)}>
                    View all supported file types
                </GelButton>
            </div>
            <GelModal
                width="sm"
                height={getGelTokens().global.sizeBaseUnit * 80}
                open={openModal}
                onClose={() => setOpenModal(false)}
                sticky
                title="Supported file types"
            >
                <FileTypes></FileTypes>
            </GelModal>
            <div
                style={{
                    paddingRight: getGelTokens().global.sizeBaseX4,
                    paddingTop: getGelTokens().global.sizeBaseX4,
                }}
            >
                <div
                    style={{
                        paddingTop: getGelTokens().global.sizeBaseX4,
                        marginTop: getGelTokens().global.sizeBaseX4,
                        border: "1px solid" + getGelTokens().global.themeColorBorderDefault,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            paddingLeft: getGelTokens().global.sizeBaseX4,
                            paddingRight: getGelTokens().global.sizeBaseX4,
                            paddingBottom: getGelTokens().global.sizeBaseX4,
                            borderBottom:
                                "1px solid" + getGelTokens().global.themeColorBorderDefault,
                        }}
                    >
                        <GelProgressStatus stepNum={1} active></GelProgressStatus>
                        <GelLabel
                            style={{
                                paddingBottom: getGelTokens().global.sizeBaseX1,
                            }}
                        >
                            Death Certificate
                        </GelLabel>
                    </div>
                    <div
                        style={{
                            paddingTop: getGelTokens().global.sizeBaseX4,
                            paddingLeft: getGelTokens().global.sizeBaseX4,
                            paddingRight: getGelTokens().global.sizeBaseX4,
                        }}
                    >
                        <Uploader
                            lodgement={lodgement}
                            setLodgement={setLodgement}
                            type="deathcertificate"
                            hasNoUploadedFilesError={props.hasNoUploadedFilesError}
                            setHasNoUploadedFilesError={setHasNoUploadedFilesError}
                            setIsUploading={setIsUploading}
                        />
                    </div>
                </div>
                <div
                    style={{
                        paddingTop: getGelTokens().global.sizeBaseX4,
                        marginTop: getGelTokens().global.sizeBaseX4,
                        border: "1px solid" + getGelTokens().global.themeColorBorderDefault,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            paddingLeft: getGelTokens().global.sizeBaseX4,
                            paddingRight: getGelTokens().global.sizeBaseX4,
                            paddingBottom: getGelTokens().global.sizeBaseX4,
                            borderBottom:
                                "1px solid" + getGelTokens().global.themeColorBorderDefault,
                        }}
                    >
                        <GelProgressStatus stepNum={2} active></GelProgressStatus>
                        <GelLabel
                            style={{
                                paddingBottom: getGelTokens().global.sizeBaseX1,
                            }}
                        >
                            Certified ID
                        </GelLabel>
                    </div>
                    <div
                        style={{
                            paddingTop: getGelTokens().global.sizeBaseX4,
                            paddingLeft: getGelTokens().global.sizeBaseX4,
                            paddingRight: getGelTokens().global.sizeBaseX4,
                        }}
                    >
                        <Uploader
                            lodgement={lodgement}
                            setLodgement={setLodgement}
                            type="certifiedid"
                            hasNoUploadedFilesError={props.hasNoUploadedFilesError}
                            setHasNoUploadedFilesError={setHasNoUploadedFilesError}
                            setIsUploading={setIsUploading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeathClaimDocuments;