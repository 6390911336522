import {
  GelButton,
  GelPageLayout,
  GelPageContent,
  GelPageHeader,
  GelIcon,
  GelBoxLayout,
  GelHeading4,
  GelContainerLite,
  GelColumnLayout,
  GelCol,
  GelRow,
  GelParagraph,
} from "@tal-gel/components";
import styled from "@emotion/styled";
import { getGelTokens } from "@tal-gel/theming";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { exitToGHQ } from "./GhqLogout";

const FinishStatus = (props: any) => {
  const { lodgementId } = props;
  const [claims, setClaims] = useState([]);
  const [fineosClaims, setFineosClaims] = useState([]);

  const navigate = useNavigate();

  const benefitText = (benefitType: string) => {
    if (benefitType === "IP") {
      return "Income Protection (IP) - ";
    } else if (benefitType === "TI") {
      return "Terminal Illness (TI) - ";
    } else if (benefitType === "TPD") {
      return "Total Permanent Disablement (TPD) - ";
    } else if (benefitType === "Death") {
      return "Death (Dth) - ";
    }
  };

  const getClaimsQuery = `query Claims($lodgementId: String!) {
    claimsByLodgementId(lodgementId: $lodgementId) {
      claimDetailId,
      ghqClaimId,
      fineosClaimNumber,
      benefitType,
      fund {
        fundId,
        fundName
      }
    }
  }`;

  // TODO: revisit this axios call, check if its necessary
  useEffect(() => {
    try {
      const loadClaims = async () => {
        const variables = {
          lodgementId: lodgementId,
        };
        const claimsResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
          {
            query: getClaimsQuery,
            variables,
          }
        );
        setClaims(claimsResponse.data.data.claimsByLodgementId);
        const filteredClaims =
          claimsResponse.data.data.claimsByLodgementId.filter(
            (claim: any) => claim.fineosClaimNumber != null
          );

        setFineosClaims(filteredClaims);
      };
      if (lodgementId != null) loadClaims();
    } catch {
      navigate("/error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lodgementId]);

  return (
    <>
      <GelPageLayout>
        <GelPageHeader></GelPageHeader>
        <GelPageContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "stretch",
            flex: "1 0 0",
            gap: "32px",
            valign: "middle",
            background: getGelTokens().global.themeColorBackgroundLight,
            paddingTop: getGelTokens().global.gridSidenavWidth,
          }}
        >
          <StyledDiv>
            {claims.length > 0 && fineosClaims.length === claims.length && (
              <StyledSuccessDiv>
                <StyledTitleDiv>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="41"
                    viewBox="0 0 40 41"
                    fill="none"
                  >
                    <rect
                      y="0.5"
                      width="40"
                      height="40"
                      rx="20"
                      fill="#E6F3D9"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.5006 30.4999C14.1806 30.4999 13.8606 30.3774 13.6169 30.1337L6.61562 23.1337C6.12813 22.6449 6.12813 21.8537 6.61562 21.3662C7.10437 20.8774 7.89562 20.8774 8.38312 21.3662L14.5006 27.4824L31.1169 10.8662C31.6056 10.3774 32.3956 10.3774 32.8844 10.8662C33.3731 11.3549 33.3731 12.1449 32.8844 12.6337L15.3844 30.1337C15.1406 30.3774 14.8206 30.4999 14.5006 30.4999Z"
                      fill="#80C342"
                    />
                    <GelIcon
                      name="check-small"
                      width={getGelTokens().global.sizeBaseUnit * 12}
                      color={getGelTokens().brand.brandColorPrimary1}
                    />
                  </svg>

                  <GelHeading4
                    style={{
                      color: getGelTokens().global.themeColorTextDefault,
                    }}
                  >
                    Your claim has been successfully submitted
                  </GelHeading4>
                  <div>
                    <strong>Your claim number(s):</strong>
                  </div>
                </StyledTitleDiv>
                <StyledSuccDescDiv>
                  <GelContainerLite
                    style={{
                      paddingTop: getGelTokens().global.sizeBaseX5,
                    }}
                  >
                    <GelColumnLayout
                      style={{
                        backgroundColor:
                          getGelTokens().global.themeColorBackgroundDefault,
                        width: "100%",
                        border:
                          "1px solid" +
                          getGelTokens().global.themeColorBorderDefault,
                      }}
                    >
                      <GelRow>
                        <GelCol></GelCol>
                        <GelCol></GelCol>
                      </GelRow>
                      {claims.map((claim: any, index: number) => (
                        <GelRow
                          key={index}
                          style={{
                            borderBottom:
                              claims.length - 1 === index
                                ? ""
                                : "1px solid" +
                                  getGelTokens().global.themeColorBorderDefault,
                            margin: getGelTokens().global.sizeNone,
                            paddingBottom: getGelTokens().global.sizeBaseX4,
                          }}
                        >
                          <GelCol
                            style={{
                              maxWidth: "50%",
                              textAlign: "left",
                            }}
                          >
                            <GelParagraph
                              as="small"
                              style={{
                                color:
                                  getGelTokens().global.themeColorTextDefault,
                              }}
                            >
                              {benefitText(claim.benefitType)}
                              {claim.fund?.fundName}
                            </GelParagraph>
                          </GelCol>
                          <GelCol
                            style={{
                              maxWidth: "50%",
                              textAlign: "left",
                            }}
                          >
                            <GelParagraph
                              as="small"
                              style={{
                                color:
                                  getGelTokens().global.themeColorTextLight,
                              }}
                            >
                              {claim.fineosClaimNumber}
                            </GelParagraph>
                          </GelCol>
                        </GelRow>
                      ))}
                    </GelColumnLayout>
                  </GelContainerLite>
                </StyledSuccDescDiv>
              </StyledSuccessDiv>
            )}
            {claims.length > 0 && fineosClaims.length < claims.length && (
              <StyledSuccessDiv>
                <StyledTitleDiv>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="41"
                    viewBox="0 0 40 41"
                    fill="none"
                  >
                    <rect
                      y="0.5"
                      width="40"
                      height="40"
                      rx="20"
                      fill="#E6F3D9"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.5006 30.4999C14.1806 30.4999 13.8606 30.3774 13.6169 30.1337L6.61562 23.1337C6.12813 22.6449 6.12813 21.8537 6.61562 21.3662C7.10437 20.8774 7.89562 20.8774 8.38312 21.3662L14.5006 27.4824L31.1169 10.8662C31.6056 10.3774 32.3956 10.3774 32.8844 10.8662C33.3731 11.3549 33.3731 12.1449 32.8844 12.6337L15.3844 30.1337C15.1406 30.3774 14.8206 30.4999 14.5006 30.4999Z"
                      fill="#80C342"
                    />
                    <GelIcon
                      name="check-small"
                      width={getGelTokens().global.sizeBaseUnit * 12}
                      color={getGelTokens().brand.brandColorPrimary1}
                    />
                  </svg>

                  <GelHeading4
                    style={{
                      color: getGelTokens().global.themeColorTextDefault,
                    }}
                  >
                    We have received your claim and it is pending eligibility
                    checks
                  </GelHeading4>
                  <p
                    style={{
                      font: "Pluto Sans",
                      display: "block",
                      color: getGelTokens().global.themeColorTextLight,
                      fontSize: getGelTokens().global.fontSizeBodyXLarge,
                      fontWeight: "400",
                      lineHeight: "22.5px",
                      letterSpacing: "-0.25px",
                    }}
                  >
                    While we perform eligibility checks, use the reference
                    number(s) below if you need to<br></br>contact TAL. After
                    the eligibility checks are done, the reference number(s)
                    will be updated to<br></br>6-digit claim number(s). There
                    are no further actions required at this time.
                  </p>
                  <div>
                    <strong>Your reference number(s):</strong>
                  </div>
                </StyledTitleDiv>
                <StyledSuccDescDiv>
                  <GelContainerLite
                    style={{
                      paddingTop: getGelTokens().global.sizeBaseX5,
                    }}
                  >
                    <GelColumnLayout
                      style={{
                        backgroundColor:
                          getGelTokens().global.themeColorBackgroundDefault,
                        width: "100%",
                        border:
                          "1px solid" +
                          getGelTokens().global.themeColorBorderDefault,
                      }}
                    >
                      <GelRow>
                        <GelCol></GelCol>
                        <GelCol></GelCol>
                      </GelRow>
                      {claims.map((claim: any, index: number) => (
                        <GelRow
                          key={index}
                          style={{
                            borderBottom:
                              claims.length - 1 === index
                                ? ""
                                : "1px solid" +
                                  getGelTokens().global.themeColorBorderDefault,
                            margin: getGelTokens().global.sizeNone,
                            paddingBottom: getGelTokens().global.sizeBaseX4,
                          }}
                        >
                          <GelCol
                            style={{
                              maxWidth: "50%",
                              textAlign: "left",
                            }}
                          >
                            <GelParagraph
                              as="small"
                              style={{
                                color:
                                  getGelTokens().global.themeColorTextDefault,
                              }}
                            >
                              {benefitText(claim.benefitType)}
                              {claim.fund?.fundName}
                            </GelParagraph>
                          </GelCol>
                          <GelCol
                            style={{
                              maxWidth: "50%",
                              textAlign: "left",
                            }}
                          >
                            {claim.fineosClaimNumber && (
                              <GelParagraph
                                as="small"
                                style={{
                                  color:
                                    getGelTokens().global.themeColorTextLight,
                                }}
                              >
                                {claim.fineosClaimNumber}
                              </GelParagraph>
                            )}
                            {claim.fineosClaimNumber == null && (
                              <GelParagraph
                                as="small"
                                style={{
                                  color:
                                    getGelTokens().global.themeColorTextLight,
                                }}
                              >
                                GHQ_{claim.ghqClaimId}
                              </GelParagraph>
                            )}
                          </GelCol>
                        </GelRow>
                      ))}
                    </GelColumnLayout>
                  </GelContainerLite>
                </StyledSuccDescDiv>
              </StyledSuccessDiv>
            )}
            <GelBoxLayout
              space="auto"
              style={{
                paddingTop: getGelTokens().global.sizeBaseX10,
                align: "center",
              }}
            >
              <GelButton name="" secondary medium onClick={exitToGHQ}>
                EXIT TO HOME PAGE
              </GelButton>
            </GelBoxLayout>
          </StyledDiv>
        </GelPageContent>
      </GelPageLayout>
    </>
  );
};

const StyledSuccessDiv = styled.div`
  ${() =>
    `
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background-color: #FFFFFF;
   
  `}
`;

const StyledDiv = styled.div`
  ${() =>
    `
    valign: "middle",
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align: center;
  `}
`;

const StyledTitleDiv = styled.div`
  ${() =>
    `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    
  `}
`;

const StyledSuccDescDiv = styled.div`
  ${() =>
    `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    
  `}
`;

export default FinishStatus;
