import {
  GelBoxLayout,
  GelButton,
  GelCheckbox,
  GelCheckboxButtonGroup,
  GelDateInput,
  GelForm,
  GelFormField,
  GelParagraph,
  GelRadio,
  GelRadioButtonGroup,
  useGelFormData,
  GelModal,
  GelTextInput,
  GelHeading5,
} from "@tal-gel/components";
import { StyledFormContainer, StyledFormContainerMain } from "./GhqForm";
import { getGelTokens } from "@tal-gel/theming";
import { useEffect, useState } from "react";
import axios from "axios";
import { Fund } from "../../models/Fund";
import { useNavigate, useParams } from "react-router-dom";
import { exitToGHQ } from "./GhqLogout";

interface BenefitProps {
  funds: Fund[] | null;
  lodgement: any;
  saveLodgement: (lodgement: any) => any;
  setScreen: (screen: string) => void;
  setLoading: (loading: boolean) => void;
  setLodgementType : (lodgementType: string) => void;
}

interface Benefit {
  benefitName: string;
  effectiveDate: string;
}

export const GhqBenefitDetailsStep = (props: BenefitProps) => {
  const { lodgement, saveLodgement, setScreen, setLoading, setLodgementType } = props;
  const [showClaimsModal, setShowClaimsModal] = useState(false);
  const [disableProceedButton, setDisableProceedButton] = useState(false);
  const [benefitErrorMessage, setBenefitErrorMessage] = useState("");
  const [benefitErrorMessage1, setBenefitErrorMessage1] = useState("");
  const [benefitErrorMessage2, setBenefitErrorMessage2] = useState("");
  const [nonAvailableBenefits, setNonAvailableBenefit] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean | null>(true);
  const [saveContinueDisable, setSaveContinueDisable] = useState<
    boolean | null
  >(false);
  const { funds } = props;
  const navigate = useNavigate();
  const {
    formData,
    multiStepFormData,
    setFormData,
    onFormDataChange,
    onPrevStep,
    onNextStep,
    validateAfterInit,
    shouldValidateAfterInit,
  } = useGelFormData(
    {
      claimReason: "",
      workStatus: "",
      lastDayOfWork: "",
      employed: "",
      ippCheckBox: false,
      tpdpCheckBox: false,
      tipCheckBox: false,
      tpdaCheckBox: false,
      tiaCheckBox: false,
      deathCheckBox: false,
      tiEffectiveDate: "",
      tpdEffectiveDate: "",
      ipEffectiveDate: "",
      lodgementType: "",
      ipOrchestratorId: "",
      tpdOrchestratorId: "",
      tiOrchestratorId: "",
      deathOrchestratorId: "",
    },
    "benefitDetails"
  );

  const memberDetailsResponseFields = ` ssoTokenId
    eligibleBenefits {
      benefitName,
      effectiveDate,
    }`;
  const saveMemberDetailsQuery = `mutation SaveMemberDetails($saveMemberDetailsRequest: SaveMemberDetailsRequestInput!) {
  saveMemberDetails(saveMemberDetailsRequest: $saveMemberDetailsRequest) {
        ${memberDetailsResponseFields}
    }
  }`;

  // Regex for validation: first 2 letters, followed by exactly 9 digits
  const regex = /^[A-Za-z]{2}\d{9}$/;

  const handleChange = (event: any) => {
    const value = event.target.value;
    // Validate the input against the regex
    if (value === "") {
      setIsValid(null); // No validation if input is empty
    } else {
      setIsValid(regex.test(value));
      setSaveContinueDisable(!regex.test(value));
      // if (regex.test(value)) {
      //   setSaveContinueDisable(regex.test(value));
      // } else {
      //   setSaveContinueDisable(false);
      // }
    }
    onFormDataChange(event);
  };

  const enableAwareIntegration = Number(
    process.env.REACT_APP_FEATURE_FLAG_AWAREINTEGRATION
  );

  useEffect(() => {
    setScreen("Benefit details");
  }, []);

  const selectedFund = funds
    ? funds.find((obj) => {
        return obj.fundId === lodgement.memberDetails.fundId;
      })
    : null;

  const eligibilityCutoffLastWorkingDate = selectedFund
    ? new Date(selectedFund.eligibilityCutoffLastWorkingDate)
        .toISOString()
        .substring(0, 10)
    : new Date().toISOString().substring(0, 10);

  const [ineligibleLastWorkingDateStatus, setIneligibleLastWorkingDateStatus] =
    useState<boolean>(false);

  const closeModal = () => {
    setShowClaimsModal(false);
    setLoading(false);
  };

  const [isValidLastWorkingDateStatus, setIsValidLastWorkingDateStatus] =
    useState<boolean>(true);

  const [inFutureLastWorkingDateStatus, setInFutureLastWorkingDateStatus] =
    useState<boolean>(false);

  useEffect(() => {
    if (lodgement?.benefitDetails?.benefitDetailId) {
      setFormData((prevData: any) => ({
        ...lodgement.benefitDetails,
        lastDayOfWork: lodgement.benefitDetails.lastDayOfWork?.substring(0, 10),
      }));
      validateAfterInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lodgement]);

  const updateLodgement = async () => {
    setLoading(true);
    //var eligibleBenefits = "{}";
    const saveAndProceed = async () => {
      const response = await saveLodgement({
        ...lodgement,
        benefitDetails: formData,
      });
      onNextStep();
    };

    if (
      enableAwareIntegration &&
      (selectedFund?.fundId === "1" || selectedFund?.fundId === "6")
    ) {
      if (showClaimsModal) {
        setShowClaimsModal(false);
        if (nonAvailableBenefits.length > 0) {
          nonAvailableBenefits.map((benefit, index) => {
            if (benefit === "Death") {
              formData.tipCheckBox = false;
              formData.deathCheckBox = false;
            } else if (benefit === "TotalAndPermanentDisability") {
              formData.tpdpCheckBox = false;
            } else if (benefit === "IncomeProtection") {
              formData.ippCheckBox = false;
            }
          });
        }
        await saveAndProceed();
      } else {
        var benefitMemberDetails = await saveMemberDetails();

        const selectedBenefits: string[] = [];

        if (formData.claimReason === "Death") {
          selectedBenefits.push("Death");
        }

        if (formData.ippCheckBox) {
          selectedBenefits.push("IncomeProtection");
        }
        if (formData.tpdpCheckBox) {
          selectedBenefits.push("TotalAndPermanentDisability");
        }
        if (formData.tipCheckBox) {
          selectedBenefits.push("Death");
        }

        if (benefitMemberDetails?.eligibleBenefits) {
          var benefits = benefitMemberDetails.eligibleBenefits;

          benefits.forEach((benefit: any) => {
            if (
              formData.ippCheckBox &&
              benefit.benefitName === "IncomeProtection"
            ) {
              formData.ipEffectiveDate = benefit.effectiveDate;
            }
            if (
              formData.tpdpCheckBox &&
              benefit.benefitName === "TotalAndPermanentDisability"
            ) {
              formData.tpdEffectiveDate = benefit.effectiveDate;
            }
            if (
              formData.claimReason === "Death" ||
              (formData.tipCheckBox && benefit.benefitName === "Death")
            ) {
              formData.tiEffectiveDate = benefit.effectiveDate;
            }
          });

          // Sort benefits by effectiveDate
          const sortedBenefits = benefits.sort(
            (a: any, b: any) =>
              new Date(a.effectiveDate).getTime() -
              new Date(b.effectiveDate).getTime()
          );

          // Map to store the earliest effective date for each benefitName
          const uniqueBenefitsMap = new Map<string, Benefit>();

          // Iterate through sorted benefits and add to the map
          for (const benefit of sortedBenefits) {
            if (!uniqueBenefitsMap.has(benefit.benefitName)) {
              uniqueBenefitsMap.set(benefit.benefitName, benefit);
            }
          }

          // Convert the map values to an array
          const uniqueBenefits = Array.from(uniqueBenefitsMap.values());

          const filteredBenefits = uniqueBenefits.filter((benefit: Benefit) =>
            selectedBenefits.includes(benefit.benefitName)
          );

          const availableBenefits = selectedBenefits.filter((benefitName) =>
            uniqueBenefits.some(
              (benefit) => benefit.benefitName === benefitName
            )
          );

          const nonAvailBenefits = selectedBenefits.filter(
            (benefitName) =>
              !uniqueBenefits.some(
                (benefit) => benefit.benefitName === benefitName
              )
          );

          setNonAvailableBenefit(nonAvailBenefits);

          if (filteredBenefits?.length === 0) {
            setBenefitErrorMessage(
              "The benefit and date last at work may not meet cover terms. Please check your \nsystems for active cover"
            );
            setBenefitErrorMessage1("");
            setBenefitErrorMessage2("");
            setShowClaimsModal(true);
            setDisableProceedButton(true);
          } else if (filteredBenefits.length < selectedBenefits.length) {
            setBenefitErrorMessage(
              `We were unable to establish cover for all benefit types selected  \nat the date entered. Please check your systems to identify the cover types\navailable`
            );
            setBenefitErrorMessage1(
              `If you wish to proceed with the lodgement of the ${availableBenefits
                .map((benefit) => {
                  switch (benefit) {
                    case "IncomeProtection":
                      return "Income Protection";
                    case "TotalAndPermanentDisability":
                      return "Total And Permanent Disability";
                    case "Death":
                      return "Terminal Illness";
                    default:
                      return benefit;
                  }
                })
                .join(", ")} benefit then please select proceed with lodgement.`
            );

            setBenefitErrorMessage2("");
            setShowClaimsModal(true);
          } else {
            await saveAndProceed();
          }
        }
      }
    } else {
      await saveAndProceed();
    }
  };

  async function saveMemberDetails() {
    var memberDetailsInputJson =
      `{ "saveMemberDetailsRequest": {
                    "lodgementId":"` +
      lodgement.lodgementId +
      `",` +
      `"memberDetails": ` +
      JSON.stringify(lodgement.memberDetails) +
      `,` +
      `"lastDayAtWork": "` +
      formData.lastDayOfWork +
      `"}}`;
    const saveMemberDetailsQueryVariables = JSON.parse(memberDetailsInputJson);
    try {
      const saveMemberDetailsResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
        {
          query: saveMemberDetailsQuery,
          variables: saveMemberDetailsQueryVariables,
        }
      );
      lodgement.memberDetails.memberDetailsSsoTokenId =
        saveMemberDetailsResponse.data.data.saveMemberDetails.ssoTokenId;

      return saveMemberDetailsResponse.data.data.saveMemberDetails;
    } catch {
      navigate("/err");
    }
  }

  const onLastDayOfWorkChange = (event: any) => {
    if (isLaterThanAwareCutoffDate(event.target.value)) {
      removeAwareBenefitsFromFormData();
    }
    if (formData.workStatus === "Yes") {
      setIsValidLastWorkingDateStatus(isValidDateFormat(event.target.value));
      if (isValidDateFormat(event.target.value)) {
        if (new Date(event.target.value) > new Date())
          setInFutureLastWorkingDateStatus(true);
        else {
          setInFutureLastWorkingDateStatus(false);
          if (
            new Date(event.target.value) <=
            new Date(eligibilityCutoffLastWorkingDate)
          ) {
            setIneligibleLastWorkingDateStatus(true);
            // setIneligibleLastWorkingDateStatus(true);
          } else setIneligibleLastWorkingDateStatus(false);
        }
      }
    }

    onFormDataChange(event);
  };

  const onClaimReasonChange = (event: any) => {
    if (event.target.value === "Death") {
      formData.workStatus = "";
      formData.tpdpCheckBox = false;
      formData.ippCheckBox = false;
      formData.tipCheckBox = false;
      formData.deathCheckBox = true;
      formData.tiEffectiveDate = null;
      formData.tpdEffectiveDate = null;
      formData.ipEffectiveDate = null;
    } else {
      formData.deathCheckBox = false;
    }

    onFormDataChange(event);
  };

  const onWorkStatusChange = (event: any) => {
    if (event.target.value === "No") {
      removeAwareBenefitsFromFormData();
    }

    onFormDataChange(event);
  };

  const removeAwareBenefitsFromFormData = () => {
    setFormData((currentFormData: any) => ({
      ...currentFormData,
      tpdaCheckBox: false,
      tiaCheckBox: false,
    }));
  };

  const onLodgementTypeChange = (event:any) =>
  {
    setLodgementType(event.target.value);

    onFormDataChange(event);
  }

  let dateTime = new Date().toISOString().substring(0, 10);
  let eligibilityCutoffLastWorkingDateString = new Date(
    eligibilityCutoffLastWorkingDate
  )
    .toISOString()
    .substring(0, 10);

  return (
    <GelForm
      labelAtTop
      disableOnSubmit
      onSubmit={updateLodgement}
      shouldValidateAfterInit={shouldValidateAfterInit}
    >
      <StyledFormContainerMain>
        <StyledFormContainer>
          <GelFormField label="What is the main cause of the claim?">
            <GelRadioButtonGroup
              name="claimReason"
              value={formData.claimReason}
              onChange={onClaimReasonChange}
              required={!formData.claimReason}
              errorMsg={{
                required: "Please select one option",
              }}
            >
              <GelRadio style={{ width: "32.5%" }} value={"Illness"}>
                Illness
              </GelRadio>
              <GelRadio style={{ width: "32.5%" }} value={"Injury"}>
                Injury
              </GelRadio>
              <GelRadio style={{ width: "32.5%" }} value={"Death"}>
                Death
              </GelRadio>
            </GelRadioButtonGroup>
          </GelFormField>

          {formData.claimReason === "Death" && (
            <>
              <GelFormField
                name="lastDayOfWork"
                label="What was the date of Death?"
              >
                <GelDateInput
                  name="lastDayOfWork"
                  onChange={onLastDayOfWorkChange}
                  value={formData.lastDayOfWork}
                  lt={dateTime}
                  gt={eligibilityCutoffLastWorkingDate}
                  errorMsg={{
                    required: "Please enter the date of death",
                    format: "Please enter a valid date",
                    compare:
                      new Date(formData.lastDayOfWork) <=
                      new Date(eligibilityCutoffLastWorkingDate)
                        ? "This date is prior to TAL being on risk for this policy. Please contact the Superfund to discuss"
                        : "Please enter a date that is in the past",
                  }}
                  required
                />
              </GelFormField>
              {["1"].includes(multiStepFormData.memberDetails.fundId) && (
                <GelFormField label="Did the cause of death occur during the course of employment?">
                  <GelRadioButtonGroup
                    name="employed"
                    value={formData.employed}
                    onChange={onFormDataChange}
                    required={
                      formData.claimReason !== "Death" && !formData.employed
                    }
                    errorMsg={{
                      required: "Please select one option",
                    }}
                  >
                    <GelRadio style={{ width: "48%" }} value="Yes">
                      On Duty
                    </GelRadio>
                    <GelRadio style={{ width: "48%" }} value="No">
                      Off Duty
                    </GelRadio>
                  </GelRadioButtonGroup>
                </GelFormField>
              )}
              <GelParagraph
                style={{
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgb(25, 25, 25)",
                }}
              >
                Fund reference
              </GelParagraph>
              <GelParagraph>
                If an OrchestratorId already exists for this claim them please
                enter this.
              </GelParagraph>
              <GelParagraph>
                If not entered we will create one at claim lodgement
              </GelParagraph>
              <GelFormField label="">
                <GelTextInput
                  name="deathOrchestratorId"
                  value={formData.deathOrchestratorId}
                  onChange={handleChange}
                  placeholder="Death OrchestratorId"
                />
              </GelFormField>
              {isValid === false && (
                <p style={{ color: "red" }}>
                  Please enter a valid Orchestrator ID. Example: SS123456789
                </p>
              )}
            </>
          )}
          {(formData.claimReason === "Illness" ||
            formData.claimReason === "Injury") && (
            <>
              <GelFormField label="Did the member stop working due to illness or injury?">
                <GelRadioButtonGroup
                  name="workStatus"
                  label="workStatus"
                  value={formData.workStatus}
                  onChange={onWorkStatusChange}
                  required={
                    !formData.workStatus && formData.claimReason !== "Death"
                  }
                  errorMsg={{
                    required: "Please select one option",
                  }}
                >
                  <GelRadio style={{ width: "48%" }} value="Yes">
                    Yes
                  </GelRadio>
                  <GelRadio style={{ width: "48%" }} value="No">
                    No
                  </GelRadio>
                </GelRadioButtonGroup>
              </GelFormField>

              {formData.workStatus === "Yes" && (
                <GelFormField
                  name="lastDayOfWork"
                  label="Last Day of Work due to Illness or Injury"
                >
                  <GelDateInput
                    name="lastDayOfWork"
                    onChange={onLastDayOfWorkChange}
                    value={formData.lastDayOfWork}
                    lt={dateTime}
                    gt={eligibilityCutoffLastWorkingDate}
                    errorMsg={{
                      required: "Please enter the last day of work",
                      format: "Please enter a valid date",
                      compare:
                        new Date(formData.lastDayOfWork) <=
                        new Date(eligibilityCutoffLastWorkingDate)
                          ? "This date is prior to TAL being on risk for this policy. Please contact the Superfund to discuss"
                          : "Please enter a date that is in the past",
                    }}
                    required
                  />
                </GelFormField>
              )}

              {/* Render employment status field for specific fund IDs */}
              {["1", "7"].includes(multiStepFormData.memberDetails.fundId) && (
                <GelFormField label="Did the illness or injury occur during the course of employment?">
                  <GelRadioButtonGroup
                    name="employed"
                    value={formData.employed}
                    onChange={onFormDataChange}
                    required={
                      formData.claimReason !== "Death" && !formData.employed
                    }
                    errorMsg={{
                      required: "Please select one option",
                    }}
                  >
                    <GelRadio style={{ width: "48%" }} value="Yes">
                      On Duty
                    </GelRadio>
                    <GelRadio style={{ width: "48%" }} value="No">
                      Off Duty
                    </GelRadio>
                  </GelRadioButtonGroup>
                </GelFormField>
              )}

              {/* Benefit Type Selection */}
              <GelFormField
                label="Select the benefit type(s) to claim"
                tooltip="You can lodge a claim for multiple benefits, but you can only select either Total Permanent Disability or Terminal Illness per fund."
                style={{ marginBottom: "8px" }}
              >
                <GelParagraph>
                  The member may be eligible to claim on the below benefit types
                  - TAL will review this on submission.
                </GelParagraph>

                {/* Conditional Checkbox Group Rendering based on fund ID */}
                {["1", "7"].includes(
                  multiStepFormData.memberDetails.fundId
                ) && (
                  <GelCheckboxButtonGroup
                    name="pbriBenefitTypes"
                    vertical
                    onChange={onFormDataChange}
                    required={formData.claimReason !== "Death"}
                    min={1}
                    errorMsg={{
                      required: "Please select at least one benefit from PBRI",
                    }}
                  >
                    <GelCheckbox
                      name="ippCheckBox"
                      checked={formData.ippCheckBox}
                      disabled={
                        !isValidDateFormat(formData.lastDayOfWork) ||
                        (isValidDateFormat(formData.lastDayOfWork) &&
                          new Date(formData.lastDayOfWork) >
                            new Date("2024-09-30"))
                      }
                    >
                      Income Protection (IP) - PBRI
                    </GelCheckbox>
                    <GelCheckbox
                      name="tpdpCheckBox"
                      disabled={
                        formData.tipCheckBox ||
                        !isValidDateFormat(formData.lastDayOfWork) ||
                        (isValidDateFormat(formData.lastDayOfWork) &&
                          new Date(formData.lastDayOfWork) >
                            new Date("2024-09-30"))
                      }
                      checked={formData.tpdpCheckBox}
                    >
                      Total Permanent Disability (TPD) - PBRI
                    </GelCheckbox>
                    <GelCheckbox
                      name="tipCheckBox"
                      disabled={
                        formData.tpdpCheckBox ||
                        !isValidDateFormat(formData.lastDayOfWork) ||
                        (isValidDateFormat(formData.lastDayOfWork) &&
                          new Date(formData.lastDayOfWork) >
                            new Date("2024-09-30"))
                      }
                      checked={formData.tipCheckBox}
                    >
                      Terminal Illness (TI) - PBRI
                    </GelCheckbox>
                  </GelCheckboxButtonGroup>
                )}

                {/* Checkbox Group for Fund IDs 6 and 9 */}
                {["6", "9"].includes(
                  multiStepFormData.memberDetails.fundId
                ) && (
                  <GelCheckboxButtonGroup
                    name="pbriBenefitTypes"
                    vertical
                    onChange={onFormDataChange}
                    required
                    min={1}
                    errorMsg={{
                      required: "Please select at least one benefit from PBRI",
                    }}
                  >
                    <GelCheckbox
                      name="ippCheckBox"
                      checked={formData.ippCheckBox}
                      disabled={
                        (!isValidDateFormat(formData.lastDayOfWork) ||
                          new Date(formData.lastDayOfWork) > new Date()) &&
                        formData.workStatus === "Yes"
                      }
                    >
                      Income Protection (IP)
                    </GelCheckbox>
                    <GelCheckbox
                      name="tpdpCheckBox"
                      disabled={
                        formData.tipCheckBox ||
                        ((!isValidDateFormat(formData.lastDayOfWork) ||
                          new Date(formData.lastDayOfWork) > new Date()) &&
                          formData.workStatus === "Yes")
                      }
                      checked={formData.tpdpCheckBox}
                    >
                      Total Permanent Disability (TPD)
                    </GelCheckbox>
                    <GelCheckbox
                      name="tipCheckBox"
                      disabled={
                        formData.tpdpCheckBox ||
                        ((!isValidDateFormat(formData.lastDayOfWork) ||
                          new Date(formData.lastDayOfWork) > new Date()) &&
                          formData.workStatus === "Yes")
                      }
                      checked={formData.tipCheckBox}
                    >
                      Terminal Illness (TI)
                    </GelCheckbox>
                  </GelCheckboxButtonGroup>
                )}

                {/* Default Checkbox Group for other fund IDs */}
                {!["1", "7", "6", "9"].includes(
                  multiStepFormData.memberDetails.fundId
                ) && (
                  <GelCheckboxButtonGroup
                    name="otherBenefitTypes"
                    vertical
                    onChange={onFormDataChange}
                    required
                    min={1}
                    errorMsg={{
                      required: "Please select at least one benefit type",
                    }}
                  >
                    <GelCheckbox
                      name="ippCheckBox"
                      checked={formData.ippCheckBox}
                      disabled={
                        (new Date(formData.lastDayOfWork) <=
                          new Date(eligibilityCutoffLastWorkingDate) ||
                          !isValidDateFormat(formData.lastDayOfWork) ||
                          new Date(formData.lastDayOfWork) > new Date()) &&
                        formData.workStatus === "Yes"
                      }
                    >
                      Income Protection (IP)
                    </GelCheckbox>
                    <GelCheckbox
                      name="tpdpCheckBox"
                      disabled={
                        formData.tipCheckBox ||
                        ((new Date(formData.lastDayOfWork) <=
                          new Date(eligibilityCutoffLastWorkingDate) ||
                          !isValidDateFormat(formData.lastDayOfWork) ||
                          new Date(formData.lastDayOfWork) > new Date()) &&
                          formData.workStatus === "Yes")
                      }
                      checked={formData.tpdpCheckBox}
                    >
                      Total Permanent Disability (TPD)
                    </GelCheckbox>
                  </GelCheckboxButtonGroup>
                )}
              </GelFormField>
              {(formData.ippCheckBox ||
                formData.tpdpCheckBox ||
                formData.tipCheckBox) && (
                <>
                  <GelParagraph
                    style={{
                      fontWeight: "700",
                      fontSize: "15px",
                      color: "rgb(25, 25, 25)",
                    }}
                  >
                    Fund reference
                  </GelParagraph>
                  <GelParagraph>
                    If an OrchestratorId already exists for this claim them
                    please enter this.
                  </GelParagraph>
                  <GelParagraph>
                    If not entered we will create one at claim lodgement
                  </GelParagraph>
                </>
              )}
              {formData.ippCheckBox && (
                <GelFormField label="">
                  <GelTextInput
                    name="ipOrchestratorId"
                    value={formData.ipOrchestratorId}
                    onChange={handleChange}
                    placeholder="IP OrchestratorId"
                  />
                </GelFormField>
              )}

              {formData.tpdpCheckBox && (
                <GelFormField label="">
                  <GelTextInput
                    name="tpdOrchestratorId"
                    value={formData.tpdOrchestratorId}
                    onChange={handleChange}
                    placeholder="TPD OrchestratorId"
                    //regex={"^[a-zA-Z-'\\s]*$"}
                    // errorMsg={{
                    //   required: "Please enter the first name",
                    //   regex:
                    //     "Please enter letters, spaces, dashes (-) and apostrophes (') only",
                    // }}
                    // required
                  />
                </GelFormField>
              )}
              {formData.tipCheckBox && (
                <GelFormField label="">
                  <GelTextInput
                    name="tiOrchestratorId"
                    value={formData.tiOrchestratorId}
                    onChange={handleChange}
                    placeholder="TI OrchestratorId"
                    //regex={"^[a-zA-Z-'\\s]*$"}
                    // errorMsg={{
                    //   required: "Please enter the first name",
                    //   regex:
                    //     "Please enter letters, spaces, dashes (-) and apostrophes (') only",
                    // }}
                    // required
                  />
                </GelFormField>
              )}
              {isValid === false && (
                <p style={{ color: "red" }}>
                  Please enter a valid Orchestrator ID. Example: SS123456789
                </p>
              )}
              <GelFormField label="Select Lodgement Type">
                <GelRadioButtonGroup
                  name="lodgementType"
                  value={formData.lodgementType}
                  onChange={onLodgementTypeChange}
                  required
                  errorMsg={{
                    required: "Please select one option",
                  }}
                >
                  <GelRadio style={{ width: "48%" }} value="Paper">
                    Paper
                  </GelRadio>
                  <GelRadio style={{ width: "48%" }} value="Tele">
                    Tele
                  </GelRadio>
                </GelRadioButtonGroup>
              </GelFormField>
            </>
          )}

          <GelBoxLayout space="auto" distribution="start">
            <GelFormField>
              <GelButton name="" secondary medium onClick={onPrevStep}>
                BACK
              </GelButton>
            </GelFormField>

            <GelFormField>
              <GelButton
                name=""
                primary
                medium
                submit
                disabled={saveContinueDisable}
              >
                SAVE & CONTINUE
              </GelButton>
            </GelFormField>

            <GelFormField>
              <GelButton name="" secondary medium onClick={exitToGHQ}>
                EXIT TO HOME PAGE
              </GelButton>
            </GelFormField>
            {showClaimsModal && (
              <GelModal
                width={getGelTokens().global.sizeBaseUnit * 200}
                open={showClaimsModal}
                onClose={closeModal}
                closeOnBackdropClick={false}
                title="Unable to Find All Benefit Types Selected"
                action={
                  <GelBoxLayout gap="small" space="auto">
                    <GelButton
                      secondary
                      large
                      width={getGelTokens().global.sizeBaseUnit * 80}
                      onClick={closeModal}
                    >
                      BACK
                    </GelButton>

                    <GelButton
                      primary
                      large
                      width={getGelTokens().global.sizeBaseUnit * 100}
                      onClick={updateLodgement}
                      disabled={disableProceedButton}
                    >
                      PROCEED WITH LODGEMENT
                    </GelButton>
                  </GelBoxLayout>
                }
              >
                <div>
                  <GelParagraph>{benefitErrorMessage}</GelParagraph>
                  <br></br>
                  <br></br>
                  <GelParagraph>{benefitErrorMessage1}</GelParagraph>
                  <br></br>
                  <GelParagraph>{benefitErrorMessage2}</GelParagraph>
                </div>
              </GelModal>
            )}
          </GelBoxLayout>
        </StyledFormContainer>
      </StyledFormContainerMain>
    </GelForm>
  );
};

function isEligibleForAwareBenefits(
  stoppedWorking: string,
  lastDayOfWork: string
): boolean {
  return stoppedWorking === "Yes" && !isLaterThanAwareCutoffDate(lastDayOfWork);
}

function isLaterThanAwareCutoffDate(lastDayOfWork: string): boolean {
  return new Date(lastDayOfWork) >= new Date("2022-12-07");
}

function isValidDateFormat(dateString: string): boolean {
  const date = new Date(dateString);

  // Check if the date is valid
  // Note: The Date object returns 'Invalid Date' for invalid dates
  return !isNaN(date.getTime());
}
