import {
  GelHeading3,
  GelPageLayout,
  GelPageContent,
  GelPageHeader,
  GelParagraph,
  GelSpinner,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import styled from "@emotion/styled";
import Form from "./GhqForm";
import { useState, useEffect, useContext } from "react";
import Review from "./GhqReview";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import GhqSubmitStatus from "./GhqSubmitStatus";
import GhqUpload from "../Upload/GhqUpload";
import GhqFinishStatus from "./GhqFinishStatus";
import { Fund } from "../../models/Fund";
import { UserContext } from "../../common/usercontext/user.context";
import GhqHeader from "../../components/layout/GhqHeader";
const memberSummaryResponseFields = `memberDetails {firstName,memberNumber,email,ssoTokenId,addressDetails{line1, line2, suburb,state,postcode},phones{phoneType,number}}, fund {fundID}`;
const saveMemberSummaryQuery = `mutation SaveMemberSummary($memberDetails: MemberDetailsInput!) {
        saveMemberSummary(memberDetails: $memberDetails) {
          ${memberSummaryResponseFields}
      }
    }`;

const allLodgementFields = `lodgementId,
          memberDetails{
            memberDetailId,
            fundId,
            sourceOfInsurance,
            memberNumber,
            awareMemberNumber,
            memberNumber,
            title,
            firstName,
            lastName,
            dateOfBirth,
            dateJoinedFund,
            memberSummarySsoTokenId,
            memberDetailsSsoTokenId
          },
          benefitDetails{
            benefitDetailId,
            claimReason,
            workStatus,
            employed,
            ippCheckBox,
            tpdpCheckBox,
            tipCheckBox,
            tpdaCheckBox,
            tiaCheckBox,
            lastDayOfWork,
            ipEffectiveDate,
            tpdEffectiveDate,
            tiEffectiveDate,
            lodgementType,
            deathCheckBox,
            ipOrchestratorId,
            tpdOrchestratorId,
            tiOrchestratorId,
            deathOrchestratorId
          },
          contactInformation{
            contactInformationId,
            emailAddress,
            numberType,
            mobile,
            home,
            address1,
            address2,
            suburb,
            state,
            postalCode
          },
          authorisation{
            authorisationId,
            thirdParty
          },
          files{
            fileId,
            fileName,
            fileStatus,
            category,
            statusReason
          },
          status`;

const getLodgementQuery = `query Lodgement($lodgementId: String!) {
        lodgementById(lodgementId: $lodgementId) {
          ${allLodgementFields}
      }
    }`;

const saveLodgementQuery = `
    mutation SaveLodgement($saveLodgementRequest: SaveLodgementRequestInput!) {
      saveLodgement(saveLodgementRequest: $saveLodgementRequest) {
        ${allLodgementFields}
    }
  }`;
const GhqLodgement = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const anyValue: any = {};
  const [funds, setFunds] = useState<Fund[] | null>(null);
  const [lodgement, setLodgement] = useState(anyValue);
  const { lodgementId } = useParams();
  const [showForm, setShowForm] = useState(!lodgementId);
  const [showReview, setShowReview] = useState(false);
  const [showFinish, setShowFinish] = useState(false);
  const [showStatus, setShowStatus] = useState("none"); // change to enum, show submit status, none to
  const [status, setStatus] = useState("");
  const [selectedFund, setSelectedFund] = useState("");
  const [screen, setScreen] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [memberDetailsMessage, setMemberDetailsMessage] = useState("");
  const [benefitDetailsMessage, setBenefitDetailsMessage] = useState("");
  const [contactDetails, setContactDetails] = useState(anyValue);
  const [lodgementType, setLodgementType] = useState("Paper");

  const navigate = useNavigate();
  const enableAwareIntegration = Number(
    process.env.REACT_APP_FEATURE_FLAG_AWAREINTEGRATION
  );

  const loadFunds = async () => {
    setLoading(true);
    try {
      const fundsResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
        {
          query: `query {
                        funds {
                                fundId,
                                fundName,
                                fundContact,
                                policyManagerId,
                                eligibilityCutoffLastWorkingDate,
                                fundAlias,
                                deletedAt,
                                documentUploadEmailAddress,
                                helpText,
                                memberDetailsLoadingMessage,
                                benefitDetailsLoadingMessage,
                                contactInformationLoadingMessage,
                                authorisationLoadingMessage,
                                integratedFundId,
                                nonIntegratedFundId
                                }
                  }`,
        }
      );
      var fetchedFunds: Fund[] = fundsResponse.data.data.funds;
      var enabledFunds = fetchedFunds.filter(
        (fund: Fund) => fund.deletedAt === null
      );
      // In case there are mutliple funds with same fundname, we will only keep the first one
      // enabledFunds = enabledFunds.filter(
      //     (fund: Fund, index: number, self: Fund[]) =>
      //         index === self.findIndex((f: Fund) => f.fundName === fund.fundName));
      setFunds(enabledFunds);
    } catch {
      navigate("/err");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadFunds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadLodgement = async () => {
      setLoading(true);
      const variables = {
        lodgementId: lodgementId,
      };
      try {
        if (!funds) {
          await loadFunds(); // Call the loadFunds function to fetch funds if not already loaded
        }
        const existingLodgementResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
          {
            query: getLodgementQuery,
            variables,
          }
        );
        setLodgement(existingLodgementResponse.data.data.lodgementById);
        setSelectedFund(
          existingLodgementResponse.data.data.lodgementById.memberDetails.fundId
        );
        setSelectedFundAndMessage(
          existingLodgementResponse.data.data.lodgementById.memberDetails.fundId
        );
        setStatus(lodgement?.status);
        if (
          lodgement?.status === "PendingEligibilityChecks" ||
          lodgement?.status === "Submitted"
        )
          setShowFinish(true);
      } catch {
        navigate("/err");
      } finally {
        setLoading(false);
      }
    };
    if (lodgementId != null) loadLodgement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lodgementId, lodgement?.status, funds]);

  useEffect(() => {
    setShowForm(lodgement?.status === "PendingDetails" || !lodgementId);
  }, [lodgement?.status]);

  const getIntegratedFundId = (fundId: string) => {
    const selectedFund = funds?.find((fund) => fund.fundId === fundId);
    return selectedFund?.integratedFundId;
  };

  const getNonIntegratedFundId = (fundId: string) => {
    const selectedFund = funds?.find((fund) => fund.fundId === fundId);
    return selectedFund?.nonIntegratedFundId;
  };

  const setSelectedFundAndMessage = (fundId: string) => {
    setSelectedFund(fundId);
    if (fundId === "1" || fundId === "6") {
      const selectedFund = funds?.find((fund) => fund.fundId === fundId);

      const memberDetailsLoadingMessage =
        selectedFund?.memberDetailsLoadingMessage ??
        "Default member details loading message";
      const benefitDetailsLoadingMessage =
        selectedFund?.benefitDetailsLoadingMessage ??
        "Default benefit details loading message";

      setMemberDetailsMessage(memberDetailsLoadingMessage);
      setBenefitDetailsMessage(benefitDetailsLoadingMessage);
      if (screen == "" || screen === "Member details") {
        setLoadingMessage(memberDetailsLoadingMessage);
      } else if (screen === "Benefit details") {
        setLoadingMessage(benefitDetailsLoadingMessage);
      } else {
        setLoadingMessage("");
      }
      console.log("Loading message", loadingMessage);
    } else {
      setLoadingMessage("");
      setMemberDetailsMessage("");
    }
  };

  const setScreenAndMessage = (screen: string) => {
    setScreen(screen);
    if (screen === "Member details") {
      setLoadingMessage(memberDetailsMessage);
    } else if (screen === "Benefit details") {
      setLoadingMessage(benefitDetailsMessage);
    } else {
      setLoadingMessage("");
    }
    console.log("Loading message", loadingMessage);
  };

  const saveLodgement = async (lodgement: any) => {
    var isMemberPresent = true;
    // Initialize an empty json array
    // var eligibleBenefits = "{}";
    setLoading(true);
    if (
      (selectedFund === "1" ||
        selectedFund === "5" ||
        selectedFund === "6" ||
        lodgement.memberDetails.fundId === "1" ||
        lodgement.memberDetails.fundId === "5" ||
        lodgement.memberDetails.fundId === "6") &&
      enableAwareIntegration === 1
    ) {
      if (screen === "Member details")
        isMemberPresent = await checkMemberSummary();
      // if (
      //   screen === "Benefit details" &&
      //   lodgement.benefitDetails.lastDayOfWork
      // )
      //   eligibleBenefits = await saveMemberDetails();
      // console.log(eligibleBenefits);
    }

    if (!isMemberPresent) {
      setLoading(false);
      return isMemberPresent;
    } else {
      const variables = {
        saveLodgementRequest: {
          requestOperation: "Add",
          lodgement,
        },
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
          {
            query: saveLodgementQuery,
            variables,
          }
        );
        setLodgement(response.data.data.saveLodgement);
        return response;
      } catch {
        navigate("/err");
      } finally {
        setLoading(false);
      }
    }

    async function checkMemberSummary() {
      var isMemberPresent = true;

      var memberDetailsJson =
        `{
            "memberDetails": ` +
        JSON.stringify(lodgement.memberDetails) +
        `}`;
      const getmemberSummaryQueryVariables = JSON.parse(memberDetailsJson);
      try {
        const memberSummaryResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/graphql/`,
          {
            query: saveMemberSummaryQuery,
            variables: getmemberSummaryQueryVariables,
          }
        );
        var memberSummary = memberSummaryResponse.data.data.saveMemberSummary;
        if (memberSummary.memberDetails == null) {
          isMemberPresent = false;
        } else {
          var integFundId = getIntegratedFundId(selectedFund);
          if (
            !lodgement.memberDetails.memberNumber.indexOf("talinternal") &&
            memberSummary.memberDetails.firstName.toLowerCase() !==
              lodgement.memberDetails.firstName.toLowerCase()
          ) {
            isMemberPresent = false;
          } else if (!integFundId?.includes(memberSummary.fund.fundID)) {
            isMemberPresent = false;
          }
          lodgement.memberDetails.memberSummarySsoTokenId =
            memberSummaryResponse.data.data.saveMemberSummary.memberDetails.ssoTokenId;

          setContactDetails({
            ...memberSummary.memberDetails.addressDetails,
            phones: [...memberSummary.memberDetails.phones],
            email: memberSummary.memberDetails.email,
          });
        }
      } catch {
        if (selectedFund == "1") {
          lodgement.memberDetails.fundId = getNonIntegratedFundId(selectedFund);
          setSelectedFund(lodgement.memberDetails.fundId);
          isMemberPresent = true;
        } else if (selectedFund == "6") {
          lodgement.memberDetails.fundId = getNonIntegratedFundId(selectedFund);
          setSelectedFund(lodgement.memberDetails.fundId);
          isMemberPresent = true;
        }
      }

      return isMemberPresent;
    }

    //
    //    try {
    //
    //        var memberSummary = memberSummaryResponse.data.data.memberSummary;
    //        if (
    //            memberSummary.memberDetails.firstName.toLowerCase() !==
    //            lodgement.memberDetails.firstName.toLowerCase()
    //        ) {
    //            isMemberPresent = false;
    //        }
    //    } catch {
    //        isMemberPresent = false;
    //    }
    //}

    //return isMemberPresent;
  };

  useEffect(() => {
    if (loading) {
      setProgress(0);
    }
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }

        return prev + 10;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [loading]);

  return (
    <>
      <GelPageLayout>
        <GelPageHeader>
          <GhqHeader />
        </GelPageHeader>
        <GelPageContent>
          {showStatus === "none" && showForm && (
            <>
              <StyledLodgementContainer>
                <GelHeading3>Start a new claim</GelHeading3>
              </StyledLodgementContainer>
              <GelParagraph
                style={{
                  padding: "32px 64px",
                  textAlign: "left",
                }}
              >
                All fields are mandatory unless otherwise specified
              </GelParagraph>
              {loading &&
                (enableAwareIntegration === 1 &&
                selectedFund &&
                selectedFund !== null &&
                (selectedFund === "1" || selectedFund === "6") ? (
                  <GelSpinner
                    large
                    overlay
                    overlayDescription={
                      <GelParagraph inverse>
                        <div style={{ textAlign: "center" }}>
                          {progress}% complete
                        </div>
                        {loadingMessage}
                      </GelParagraph>
                    }
                  />
                ) : (
                  <GelSpinner large overlay />
                ))}

              <Form
                setShowForm={setShowForm}
                setShowReview={setShowReview}
                selectedFund={selectedFund}
                setSelectedFund={setSelectedFundAndMessage}
                saveLodgement={saveLodgement}
                lodgement={lodgement}
                contactDetails={contactDetails}
                setLodgement={setLodgement}
                funds={funds}
                setScreen={setScreenAndMessage}
                setLoading={setLoading}
                setLodgementType ={setLodgementType}
              ></Form>
            </>
          )}
          {showStatus === "none" && showReview && (
            <>
              <StyledLodgementContainer>
                <GelHeading3>Review claim</GelHeading3>
              </StyledLodgementContainer>
              <Review
                data-testid="review"
                lodgement={lodgement}
                setShowReview={setShowReview}
                setShowForm={setShowForm}
                setShowStatus={setShowStatus}
                funds={funds}
                lodgementType ={lodgementType}
                setShowFinish={setShowFinish}
              ></Review>
            </>
          )}
          {showStatus !== "none" && !showFinish && (
            <>
              <GhqSubmitStatus
                status={showStatus}
                setStatus={setStatus}
                setShowStatus={setShowStatus}
                fundContact={
                  funds?.find(
                    (fund) => fund.fundId === lodgement.memberDetails.fundId
                  )?.fundContact
                }
              ></GhqSubmitStatus>
            </>
          )}
          {status === "PendingDocuments" && !showFinish && (
            <>
              <GhqUpload
                lodgement={lodgement}
                setShowFinish={setShowFinish}
                setLodgement={setLodgement}
                funds={funds}
              ></GhqUpload>
            </>
          )}
          {showFinish && (
            <>
              <GhqFinishStatus
                lodgementId={lodgement?.lodgementId || lodgementId}
              ></GhqFinishStatus>
            </>
          )}
        </GelPageContent>
      </GelPageLayout>
    </>
  );
};

const StyledLodgementContainer = styled.div`
  ${() =>
    `
    display: flex;
    align-items: flex-start;
    background: ${getGelTokens().global.themeColorBackgroundLight};
    padding: 40px 32px;
    width: 100%;
  `}
`;

export default GhqLodgement;
