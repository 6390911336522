import Cookie from "js-cookie";

export const exitToGHQ = () => {
    const idToken = Cookie.get("idtoken");
    const oktaDomain = `${process.env.REACT_APP_GHQ_OKTA_AUTH_SERVER}`;
    const route = "oauth2/v1/logout";
    const postLogoutRedirectUri =`${process.env.REACT_APP_GHQ_HOME_URL}`;

    const logoutUrl = `${oktaDomain}/${route}?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(postLogoutRedirectUri)}`;

    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");

    window.location.href = logoutUrl;
   
};