import { Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

export const RequiredGhqAuth = () => {

  const aid = Cookies.get('SsoToken');

  if (aid)
    return (<Outlet />)
  else
    return (<h1> You are not allowed to access this page </h1>);
}
