import {
  GelHeading3,
  GelPageLayout,
  GelPageContent,
  GelBoxLayout,
  GelButton,
  GelSpinner,
  GelAccordion,
} from "@tal-gel/components";
import styled from "@emotion/styled";
import { getGelTokens } from "@tal-gel/theming";
import MandatoryDocuments from "./MandatoryDocuments";
import OtherDocuments from "./OtherDocuments";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ClaimSummary from "../ClaimSummary";
import DeathClaimDocuments from "./DeathClaimDocuments";

const GhqUpload = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { lodgement, setLodgement, setShowFinish, funds } = props;
  const [hasNoUploadedFilesError, setHasNoUploadedFilesError] = useState(false);
  const navigate = useNavigate();
  // Traverse funds and fetch details on the basis of fundid

  // const documentUploadEmailAddress =
  //   funds.find((fund: any) => fund?.fundId === lodgement?.memberDetails?.fundId)
  //     ?.documentUploadEmailAddress || null;

  const fund = funds.find(
    (fund: any) => fund.fundId === lodgement?.memberDetails?.fundId
  );

  console.log("Found Fund:", fund);

  const documentUploadEmailAddress = fund
    ? fund.documentUploadEmailAddress
    : null;

  console.log("Document Upload Email Address:", documentUploadEmailAddress);

  const saveAndExit = () => {
    navigate("/");
  };

  const setIsUploadingWrapper = (isUploading: boolean) => {
    setIsUploading(isUploading);
    setHasNoUploadedFilesError(false);
  };

  const finishLodgementQuery = `
    mutation FinishLodgement($finishLodgementRequest: FinishLodgementRequestInput!) {
      finishLodgement(finishLodgementRequest: $finishLodgementRequest) {
        lodgementId
    }
  }`;

  const finishLodgement = async () => {
    if (lodgement.files?.length > 0) {
      const uploadedFiles = lodgement.files.filter(
        (file: any) => file.category !== "authorisation"
      );

      if (uploadedFiles.length === 0) {
        setHasNoUploadedFilesError(true);
        return;
      } else {
        const validFiles = uploadedFiles.filter(
          (file: any) => file.status === "2"
        );
        if (validFiles.length > 0) {
          setHasNoUploadedFilesError(false);
        }
      }
      setLoading(true);
      const variables = {
        finishLodgementRequest: {
          lodgementId: lodgement.lodgementId,
        },
      };
      try {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/graphql/`, {
          query: finishLodgementQuery,
          variables,
        });
        setShowFinish(true);
      } catch {
        navigate("/error");
      } finally {
        setLoading(false);
      }
    } else {
      setHasNoUploadedFilesError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <GelPageLayout>
        <GelPageContent>
          {loading && <GelSpinner large overlay />}
          <StyledLodgementContainer>
            <GelHeading3>Upload documents</GelHeading3>
          </StyledLodgementContainer>
          <div
            style={{
              paddingLeft: getGelTokens().global.sizeBaseX16,
              paddingTop: getGelTokens().global.sizeBaseX4,
              paddingRight: getGelTokens().global.sizeBaseX4,
              width: "60%",
            }}
          >
            <GelAccordion
              title={
                "Claim summary: " +
                lodgement?.memberDetails?.firstName +
                " " +
                lodgement?.memberDetails?.lastName
              }
            >
              <ClaimSummary lodgement={lodgement} funds={funds} />
            </GelAccordion>
          </div>
          <div
            style={{
              paddingLeft: getGelTokens().global.sizeBaseX16,
              paddingTop: getGelTokens().global.sizeBaseX4,
              width: "60%",
            }}
          >
            {loading && <GelSpinner large overlay />}
            {lodgement?.benefitDetails?.claimReason.toLowerCase() !=
              "death" && (
              <MandatoryDocuments
                lodgement={lodgement}
                setLodgement={setLodgement}
                hasNoUploadedFilesError={hasNoUploadedFilesError}
                setHasNoUploadedFilesError={setHasNoUploadedFilesError}
                setIsUploading={setIsUploadingWrapper}
              ></MandatoryDocuments>
            )}
            {lodgement?.benefitDetails?.claimReason.toLowerCase() ==
              "death" && (
              <DeathClaimDocuments
                lodgement={lodgement}
                setLodgement={setLodgement}
                hasNoUploadedFilesError={hasNoUploadedFilesError}
                setHasNoUploadedFilesError={setHasNoUploadedFilesError}
                setIsUploading={setIsUploadingWrapper}
              ></DeathClaimDocuments>
            )}
            <OtherDocuments
              lodgement={lodgement}
              setLodgement={setLodgement}
              setIsUploading={setIsUploadingWrapper}
            ></OtherDocuments>
            <StyledAlertDiv>
              <div style={{ paddingRight: "15px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.5"
                  height="22.5"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="#5aafb3"
                    fillRule="evenodd"
                    d="M16,1 C24.271,1 31,7.729 31,16 C31,24.271 24.271,31 16,31 C7.729,31 1,24.271 1,16 C1,7.729 7.729,1 16,1 Z M16,3 C8.832,3 3,8.832 3,16 C3,23.168 8.832,29 16,29 C23.168,29 29,23.168 29,16 C29,8.832 23.168,3 16,3 Z M15.0668,13.1331 C16.1328,13.1331 16.9998,14.0001 16.9998,15.0661 L16.9998,15.0661 L16.9998,19.7331 C16.9998,20.2111 17.3888,20.6001 17.8658,20.6001 L17.8658,20.6001 L18.7998,20.6001 C19.3528,20.6001 19.7998,21.0481 19.7998,21.6001 C19.7998,22.1521 19.3528,22.6001 18.7998,22.6001 L18.7998,22.6001 L17.8658,22.6001 C16.2858,22.6001 14.9998,21.3141 14.9998,19.7331 L14.9998,19.7331 L14.9998,15.0661 L14.1328,15.1331 C13.5798,15.1331 13.1328,14.6851 13.1328,14.1331 C13.1328,13.5811 13.5798,13.1331 14.1328,13.1331 L14.1328,13.1331 Z M15.5332,8.4669 C16.3422,8.4669 17.0002,9.1249 17.0002,9.9329 C17.0002,10.7419 16.3422,11.3999 15.5332,11.3999 C14.7242,11.3999 14.0662,10.7419 14.0662,9.9329 C14.0662,9.1249 14.7242,8.4669 15.5332,8.4669 Z"
                  ></path>
                </svg>
              </div>
              <div>
                <strong>
                  You won't be able to upload additional documents here after
                  this step
                </strong>
                <p
                  style={{
                    font: "Pluto Sans",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "22.5px",
                    letterSpacing: "-0.25px",
                    margin: getGelTokens().global.sizeNone,
                    color: getGelTokens().global.themeColorBlack,
                  }}
                >
                  However if you need to provide additional information you can
                  continue to upload documents via GHQ in the normal way.
                </p>
              </div>
            </StyledAlertDiv>
            <GelBoxLayout
              space="auto"
              distribution="start"
              style={{
                paddingTop: getGelTokens().global.sizeBaseX4,
                paddingLeft: getGelTokens().global.sizeBaseX16,
              }}
            >
              {/* <GelButton
                secondary
                medium
                onClick={saveAndExit}
                disabled={isUploading}
              >
                SAVE & EXIT
              </GelButton> */}
              <GelButton
                primary
                medium
                onClick={finishLodgement}
                disabled={isUploading}
              >
                FINISH
              </GelButton>
            </GelBoxLayout>
          </div>
        </GelPageContent>
      </GelPageLayout>
    </>
  );
};

const StyledLodgementContainer = styled.div`
  ${() =>
    `
    display: flex;
    align-items: flex-start;
    background: ${getGelTokens().global.themeColorBackgroundLight};
    padding: 40px 32px;
    width: 100%;
  `}
`;

const StyledAlertDiv = styled.div`
  ${() =>
    `
    background-color: #d5f0f0;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 2px;
    margin: 16px 16px 16px 0px;
    display: flex;
  `}
`;

export default GhqUpload;
