import {
  GelBoxLayout,
  GelButton,
  GelDateInput,
  GelForm,
  GelFormField,
  GelParagraph,
  GelSelect,
  GelTextInput,
  useGelFormData,
  GelIcon,
  GelLink,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { StyledFormContainer } from "./GhqForm";
import { useEffect, useState } from "react";
import { Fund } from "../../models/Fund";
import styled from "@emotion/styled";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { exitToGHQ } from "./GhqLogout";

interface MemberProps {
  funds: Fund[] | null;
  lodgement: any;
  saveLodgement: (lodgement: any) => any;
  setSelectedFund: (fundId: string) => void;
  setScreen: (screen: string) => void;
}
export const GhqMemberDetailsStep = (props: MemberProps) => {
  const { funds, setScreen } = props;
  const { lodgement, saveLodgement, setSelectedFund } = props;
  const [isMemberPresent, setIsMemberPresent] = useState(true);
  const currentDate = new Date();
  const minDate = new Date(currentDate);
  minDate.setFullYear(currentDate.getFullYear() - 15);
  const [
    pbriMemberNumberWithoutFSSUstatus,
    setPbriMemberNumberWithoutFSSUstatus,
  ] = useState<boolean>(false);
  const [uniqueFundOptionsArray, setUniqueFundOptionsArray] = useState<
    { label: string; value: string }[]
  >([]);

  const updateUniqueFundOptions = (funds: Fund[]) => {
    const uniqueFundOptions = funds.reduce<{ [key: string]: string }>(
      (acc, fund) => {
        if (!acc[fund.fundName]) {
          acc[fund.fundName] = fund.fundId;
        }
        return acc;
      },
      {}
    );

    return Object.keys(uniqueFundOptions).map((fundName) => ({
      label: fundName,
      value: uniqueFundOptions[fundName],
    }));
  };

  useEffect(() => {
    if (funds) {
      setUniqueFundOptionsArray(updateUniqueFundOptions(funds));
    }
  }, [funds]);
  // Format the date as a string (YYYY-MM-DD) to use in an input field
  const formattedMinDate = minDate.toISOString().substring(0, 10);

  const {
    formData,
    onFormDataChange,
    setFormData,
    multiStepFormData,
    onNextStep,
    validateAfterInit,
    shouldValidateAfterInit,
  } = useGelFormData(
    {
      sourceOfInsurance: "Superfund",
      //fundId: "",
      memberNumber: "",
      awareMemberNumber: "",
      title: null,
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      dateJoinedFund: "",
    },
    "memberDetails"
  );
  const navigate = useNavigate();
  const enableAwareIntegration = Number(
    process.env.REACT_APP_FEATURE_FLAG_AWAREINTEGRATION
  );

  useEffect(() => {
    setScreen("Member details");
  }, []);

  const getFundTooltip = (fundId: string) =>
    funds?.find((fund) => fund.fundId === fundId)?.helpText;

  const getHelpText = (fundName: string): string => {
    let fundId;
    if (fundName === "PBRI") {
      fundId = "1";
    } else if (fundName === "Aware") {
      fundId = "2";
    }

    if (!fundId) {
      return "Invalid fund name";
    }

    const tooltip = getFundTooltip(fundId);
    if (!tooltip) {
      return "No help text available";
    }

    return tooltip;
  };

  useEffect(() => {
    if (lodgement?.memberDetails?.memberDetailId) {
      setFormData({
        ...lodgement.memberDetails,
        dateOfBirth: lodgement.memberDetails.dateOfBirth.substring(0, 10),
        dateJoinedFund: lodgement.memberDetails.dateJoinedFund.substring(0, 10),
      });

      if (
        !uniqueFundOptionsArray.some(
          (option) => option.value === formData.fundId
        )
      ) {
        const selectedFund1 = funds?.find(
          (fund) => fund.fundId === formData.fundId
        );
        if (selectedFund1) {
          setUniqueFundOptionsArray((prevArray) => {
            const filteredArray = prevArray.filter(
              (option) => option.label !== selectedFund1.fundName
            );
            return [
              ...filteredArray,
              { label: selectedFund1.fundName, value: selectedFund1.fundId },
            ];
          });
        }
      }
      validateAfterInit();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lodgement]);

  const removeAwareBenefitTypes = (event: any) => {
    if (
      multiStepFormData?.benefitDetails?.tiaCheckBox &&
      multiStepFormData?.benefitDetails?.tpdaCheckBox
    ) {
      if (event.target.value.length === 0) {
        multiStepFormData.benefitDetails.tiaCheckBox = false;
        multiStepFormData.benefitDetails.tpdaCheckBox = false;
      }
    }
  };

  const onSubmit = async () => {
    var response = await saveLodgement({
      ...lodgement,
      memberDetails: formData,
    });
    if (!response) setIsMemberPresent(false);
    else onNextStep();
  };
  const onPbriMemberChange = (event: any) => {
    if (!event.target.value.startsWith("FSSU"))
      setPbriMemberNumberWithoutFSSUstatus(true);
    else setPbriMemberNumberWithoutFSSUstatus(false);
    setIsMemberPresent(true);
    onFormDataChange(event);
  };

  const onFirstNameChange = (event: any) => {
    setIsMemberPresent(true);
    onFormDataChange(event);
  };
  const onLastNameChange = (event: any) => {
    setIsMemberPresent(true);
    onFormDataChange(event);
  };
  const onDobChange = (event: any) => {
    setIsMemberPresent(true);
    onFormDataChange(event);
  };

  const onFundChange = (event: any) => {
    setSelectedFund(event.target.value);
    if (event.target.value !== "1") {
      if (formData.awareMemberNumber !== "") {
        setFormData((currentFormData: any) => ({
          ...currentFormData,
          awareMemberNumber: "",
        }));
      }
      if (
        lodgement &&
        lodgement.benefitDetails &&
        lodgement.benefitDetails.employed
      ) {
        lodgement.benefitDetails.employed = "";
      }
    }
    onFormDataChange(event);
  };

  let dateTime = new Date().toISOString().substring(0, 10);

  const downloadThirdPartyDocument = () => {
    var url: string = "";
    var fundAlias = "aware super";
    // Replace space with _ in fundAlias
    var fundTag = fundAlias?.replace(/\s/g, "_");
    var cmsQuery =
      `{  acpContentCollection(    where: {contentfulMetadata: {tags: {id_contains_all: "` +
      fundTag +
      `"}}}  ) {    items {      thirdPartyPortalProcedureGuide {        title        contentType        fileName        url      }    }  }}`;

    const loadDocument = async () => {
      try {
        const cmsResponse = await axios.post(
          `https://graphql.contentful.com/content/v1/spaces/` +
            process.env.REACT_APP_CONTENTFUL_SPACE_ID +
            `/environments/` +
            process.env.REACT_APP_CONTENTFUL_ENVIRONMENT +
            `?access_token=` +
            process.env.REACT_APP_CONTENTFUL_API_TOKEN,
          {
            query: cmsQuery,
          }
        );

        if (
          cmsResponse.data.data.acpContentCollection.items &&
          cmsResponse.data.data.acpContentCollection.items.length > 0
        )
          url =
            cmsResponse.data.data.acpContentCollection.items[0]
              .thirdPartyPortalProcedureGuide.url;
      } catch {
        navigate("/err");
      } finally {
      }
      // window.location.href = url;
      window.open(url, "_blank");
    };
    loadDocument();
  };

  return (
    <GelForm
      labelAtTop
      disableOnSubmit
      onSubmit={onSubmit}
      shouldValidateAfterInit={shouldValidateAfterInit}
    >
      <StyledFormContainer>
        <GelParagraph
          style={{ paddingBottom: getGelTokens().global.sizeBaseX6 }}
        >
          Please provide details about the member and the policy you are
          <br></br> intending to claim against.
        </GelParagraph>
        <GelFormField label="Select a fund">
          <GelSelect
            name="fundId"
            value={formData.fundId}
            placeholder="Please select"
            onChange={onFundChange}
            options={uniqueFundOptionsArray}
            required={!formData.fundId}
          />
        </GelFormField>
        {formData.fundId === "1" && (
          <>
            <GelFormField
              label="Police Blue Ribbon Insurance Account Number"
              tooltip={getHelpText("PBRI")}
              style={{ marginBottom: "8px" }}
            >
              <GelTextInput
                name="memberNumber"
                value={formData.memberNumber}
                onChange={onPbriMemberChange}
                regex={"^FSSU[a-zA-Z0-9]*$"}
                errorMsg={{
                  required: "Please enter the PBRI account number",
                  regex: pbriMemberNumberWithoutFSSUstatus
                    ? "PBRI account must start with FSSU"
                    : "Please enter letters and numbers only",
                }}
                required
              />
            </GelFormField>
            {/* <GelFormField
              label="Aware Account Number"
              tooltip="This number will be different to the PBRI account number field provided earlier"
              style={{ marginBottom: "8px" }}
            >
              <GelParagraph
                style={{ paddingBottom: getGelTokens().global.sizeBaseX6 }}
              >
                To claim on both PBRI and Aware policies, you must provide an
                <br></br> Aware account number. (For help finding the account
                number-click
                <GelLink onClick={downloadThirdPartyDocument}> here </GelLink> )
              </GelParagraph>
              <GelTextInput
                name="awareMemberNumber"
                value={formData.awareMemberNumber}
                onChange={onFormDataChange}
                regex={"^[a-zA-Z0-9]*$"}
                errorMsg={{
                  regex: "Please enter letters and numbers only",
                }}
                onBlur={removeAwareBenefitTypes}
              />
            </GelFormField> */}
          </>
        )}
        {formData.fundId !== "1" && formData.fundId !== "6" && (
          <GelFormField label="Member Number">
            <GelTextInput
              name="memberNumber"
              value={formData.memberNumber}
              onChange={onFormDataChange}
              regex={"^[a-zA-Z0-9]+$"}
              errorMsg={{
                required: "Please enter the Member number",
                regex: "Please enter letters and numbers only",
              }}
              required
            />
          </GelFormField>
        )}
        {formData.fundId === "6" && (
          <GelFormField label="Account Number">
            <GelTextInput
              name="memberNumber"
              value={formData.memberNumber}
              onChange={onFormDataChange}
              regex={"^[a-zA-Z0-9]+$"}
              errorMsg={{
                required: "Please enter the Account number",
                regex: "Please enter letters and numbers only",
              }}
              required
            />
          </GelFormField>
        )}
        {formData.fundId && (
          <>
            <GelFormField name="dateJoinedFund" label="Date Joined Fund">
              <GelDateInput
                name="dateJoinedFund"
                onChange={onFormDataChange}
                value={formData.dateJoinedFund}
                lte={dateTime}
                errorMsg={{
                  required: "Please enter date joined fund",
                  format: "Please enter a valid date",
                  compare: "Please enter a date that is in the past",
                }}
                required
              ></GelDateInput>
            </GelFormField>
            <GelFormField label="Title">
              <GelSelect
                name="title"
                value={formData.title}
                onChange={onFormDataChange}
                placeholder="Please select an option"
                options={[
                  { label: "Mr", value: "Mr" },
                  { label: "Mrs", value: "Mrs" },
                  { label: "Lady", value: "Lady" },
                  { label: "Ms", value: "Ms" },
                  { label: "Miss", value: "Miss" },
                  { label: "Sir", value: "Sir" },
                ]}
                errorMsg={{
                  required: "Please select a title",
                }}
                required
              />
            </GelFormField>
            <GelFormField label="First name">
              <GelTextInput
                name="firstName"
                value={formData.firstName}
                onChange={onFirstNameChange}
                regex={"^[a-zA-Z-'\\s]*$"}
                errorMsg={{
                  required: "Please enter the first name",
                  regex:
                    "Please enter letters, spaces, dashes (-) and apostrophes (') only",
                }}
                required
              />
            </GelFormField>
            <GelFormField label="Last name">
              <GelTextInput
                name="lastName"
                value={formData.lastName}
                onChange={onLastNameChange}
                regex={"^[a-zA-Z-'\\s]*$"}
                errorMsg={{
                  required: "Please enter the last name",
                  regex:
                    "Please enter letters, spaces, dashes (-) and apostrophes (') only",
                }}
                required
              />
            </GelFormField>
            <GelFormField name="dateOfBirth" label="Date of birth">
              <GelDateInput
                name="dateOfBirth"
                onChange={onDobChange}
                value={formData.dateOfBirth}
                lte={formattedMinDate}
                errorMsg={{
                  required: "Please enter the date of birth",
                  format: "Please enter a valid date",
                  compare: "life insured cannot be less than 15 years old",
                }}
                required
              ></GelDateInput>
            </GelFormField>
            {!isMemberPresent &&
              enableAwareIntegration === 1 &&
              (formData.fundId == "1" || formData.fundId == "6") && (
                <StyledAlertDiv>
                  <GelIcon name="AlertTriangle" color="red" />
                  <div style={{ marginLeft: "15px" }}>
                    <strong>No Matching Accounts Found</strong>
                    <p
                      style={{
                        font: "Pluto Sans",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "22.5px",
                        letterSpacing: "-0.25px",
                        margin: getGelTokens().global.sizeNone,
                        color: getGelTokens().global.themeColorBlack,
                      }}
                    >
                      Please check the details provided and try again
                    </p>
                  </div>
                </StyledAlertDiv>
              )}
          </>
        )}
        {/* <div style={{ display: "flex", gap: getGelTokens().global.sizeBaseX2 }}>
          <GelFormField>
            <GelButton
              disabled={!isMemberPresent}
              name=""
              primary
              medium
              submit
            >
              SAVE & CONTINUE
            </GelButton>
          </GelFormField>
          <GelFormField>
            <GelButton name="" secondary medium onClick={exitToGHQ}>
              EXIT TO HOME PAGE
            </GelButton>
          </GelFormField>
        </div> */}
         <GelBoxLayout space="auto" distribution="start">
            <GelFormField>
              <GelButton
                name=""
                primary
                medium
                submit
                disabled={!isMemberPresent}
              >
                SAVE & CONTINUE
              </GelButton>
            </GelFormField>

            <GelFormField>
              <GelButton name="" secondary medium onClick={exitToGHQ}>
                EXIT TO HOME PAGE
              </GelButton>
            </GelFormField>
          </GelBoxLayout>
      </StyledFormContainer>
    </GelForm>
  );
};

const StyledAlertDiv = styled.div`
  ${() =>
    `
    background-color: #FFA6A1;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 2px;
    margin: 16px 0;
    display: flex;
  `}
`;
