import { useOktaAuth } from '@okta/okta-react';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HomeComponent: React.FC = () => {
    const { authState } = useOktaAuth();
    const aid = Cookies.get('SsoToken');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (authState) {
            console.log(authState);
            console.log(authState.isAuthenticated);
            if (authState.isAuthenticated) {
                navigate('/dashboard');
            } else if (aid) {
                navigate('/ghq/lodgement');
            } else {
                navigate('/login');
            }
            setIsLoading(false);
        }
    }, [authState, aid, navigate]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return <div></div>;
};

export default HomeComponent;
