import {
  GelBoxLayout,
  GelButton,
  GelColumnLayout,
  GelEmailInput,
  GelForm,
  GelFormField,
  GelLabel,
  GelParagraph,
  GelPhoneNumberInput,
  GelPostcodeInput,
  GelRadio,
  GelRadioGroup,
  GelSelect,
  GelTextInput,
  useGelFormData,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { StyledFormContainer } from "./GhqForm";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { exitToGHQ } from "./GhqLogout";

interface ContactInformationProps {
  lodgement: any;
  contactDetails?: any;
  saveLodgement: (lodgement: any) => any;
  setScreen: (screen: string) => void;
}

export const ContactInformationStep = (props: ContactInformationProps) => {
  const { lodgement,contactDetails, saveLodgement, setScreen } = props;

  const {
    formData,
    onFormDataChange,
    onPrevStep,
    onNextStep,
    setFormData,
    validateAfterInit,
    shouldValidateAfterInit,
  } = useGelFormData(
    {
      numberType: "Mobile",
      mobile: contactDetails?.phones?.filter((phone: any) => phone.phoneType.toLowerCase() == "mobile")[0]?.number,
      home: "",
      emailAddress: contactDetails?.email,
      address1: contactDetails?.line1,
      address2: contactDetails?.line2,
      suburb: contactDetails?.suburb,
      state: contactDetails?.state,
      postalCode: contactDetails?.postcode,
    },
    "contactInformation"
  );

  useEffect(() => {
    if (lodgement?.contactInformation?.contactInformationId) {
      setFormData(lodgement.contactInformation);
      validateAfterInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lodgement]);

  useEffect(() => {
    setScreen("Contact Information");
  }, []);

  const updateLodgement = async () => {
    await saveLodgement({
      ...lodgement,
      contactInformation: formData,
    });
    onNextStep();
  };

  return (
    <>
      <GelForm
        labelAtTop
        disableOnSubmit
        onSubmit={updateLodgement}
        shouldValidateAfterInit={shouldValidateAfterInit}
      >
        <StyledFormContainer>
          <GelLabel
            large
            style={{
              paddingBottom: getGelTokens().global.sizeBaseX4,
            }}
          >
            Member's email
          </GelLabel>
          <GelParagraph
            style={{
              paddingBottom: getGelTokens().global.sizeBaseX4,
            }}
          ></GelParagraph>
          <GelFormField label={"Email address"}>
            <GelEmailInput
              name="emailAddress"
              maxLength={120}
              onChange={onFormDataChange}
              value={formData.emailAddress}
              required
              errorMsg={{
                required: "Please enter an email address",
                format: "Please enter a valid email address",
              }}
            ></GelEmailInput>
          </GelFormField>
          <GelLabel
            large
            style={{
              paddingBottom: getGelTokens().global.sizeBaseX6,
            }}
          >
            Member's phone number
          </GelLabel>
          <GelFormField
            style={{ paddingTop: getGelTokens().global.sizeBaseX4 }}
          >
            <GelRadioGroup
              name="numberType"
              value={formData.numberType}
              onChange={onFormDataChange}
              required
            >
              <GelRadio style={{ width: "20%" }} value={"Mobile"}>
                Mobile
              </GelRadio>
              <GelRadio style={{ width: "80%" }} value={"Home"}>
                Home
              </GelRadio>
            </GelRadioGroup>
          </GelFormField>
          {formData.numberType === "Mobile" && (
            <GelFormField label={"Mobile Number"}>
              <GelPhoneNumberInput
                name="mobile"
                onChange={onFormDataChange}
                value={formData.mobile}
                required
                regex={"^04"}
                errorMsg={{
                  required: "Please enter a phone number",
                  regex: "Please enter a valid phone number",
                }}
              />
            </GelFormField>
          )}
          {formData.numberType === "Home" && (
            <GelFormField label={"Home Number"}>
              <GelPhoneNumberInput
                name="home"
                onChange={onFormDataChange}
                value={formData.home}
                required
                errorMsg={{
                  required: "Please enter a phone number",
                  regex: "Please enter a valid phone number",
                }}
              />
            </GelFormField>
          )}
          <GelLabel
            large
            style={{
              paddingBottom: getGelTokens().global.sizeBaseX6,
            }}
          >
            Member's residential address
          </GelLabel>
          <GelParagraph
            style={{
              paddingBottom: getGelTokens().global.sizeBaseX4,
            }}
          ></GelParagraph>
          <GelFormField label={"Street address"}>
            <GelTextInput
              name="address1"
              onChange={onFormDataChange}
              value={formData.address1}
              required
              errorMsg={{
                required: "Please enter a street address",
              }}
            />
          </GelFormField>
          <GelFormField>
            <GelTextInput
              aria-label="Address2"
              name="address2"
              onChange={onFormDataChange}
              value={formData.address2}
            />
          </GelFormField>

          <GelColumnLayout distribution="1/2 1/2">
            <GelFormField label="Suburb / City">
              <GelTextInput
                name="suburb"
                onChange={onFormDataChange}
                value={formData.suburb}
                required
                errorMsg={{
                  required: "Please enter a suburb name",
                }}
              />
            </GelFormField>
            <GelFormField label="State">
              <GelSelect
                name="state"
                value={formData.state}
                onChange={onFormDataChange}
                placeholder="Please select an option"
                options={[
                  { label: "ACT", value: "ACT" },
                  { label: "NSW", value: "NSW" },
                  { label: "NT", value: "NT" },
                  { label: "QLD", value: "QLD" },
                  { label: "SA", value: "SA" },
                  { label: "TAS", value: "TAS" },
                  { label: "VIC", value: "VIC" },
                  { label: "WA", value: "WA" },
                ]}
                required
                errorMsg={{
                  required: "Please select a state",
                }}
              />
            </GelFormField>
          </GelColumnLayout>
          <GelParagraph
            style={{
              paddingBottom: getGelTokens().global.sizeBaseX4,
            }}
          ></GelParagraph>
          <GelColumnLayout distribution="1/2 1/2">
            <Child>
              <GelFormField label="Postcode">
                <GelPostcodeInput
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={onFormDataChange}
                  required
                  errorMsg={{
                    required: "Please enter a postcode",
                    length: "Please enter a valid postcode",
                  }}
                />
              </GelFormField>
            </Child>
            <Child></Child>
          </GelColumnLayout>
          <GelParagraph
            style={{
              paddingBottom: getGelTokens().global.sizeBaseX4,
            }}
          ></GelParagraph>
          <GelBoxLayout space="auto" distribution="start">
            <GelFormField>
              <GelButton name="" secondary medium onClick={onPrevStep}>
                BACK
              </GelButton>
            </GelFormField>
            <GelFormField>
              <GelButton name="" primary medium submit>
                SAVE & CONTINUE
              </GelButton>
            </GelFormField>            
            <GelFormField>
              <GelButton name="" secondary medium onClick={exitToGHQ}>
                EXIT TO HOME PAGEe
              </GelButton>
            </GelFormField>
          </GelBoxLayout>
        </StyledFormContainer>
      </GelForm>
    </>
  );
};

const Child = styled.div(() => ({}));
