import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "./views/Dashboard/Dashboard";
import Lodgement from "./views/Lodgement/Lodgement";
import SubmitStatus from "./views/SubmitStatus/SubmitStatus";
import GhqSubmitStatus from "./views/GhqLodgement/GhqSubmitStatus";
import { oktaConfig } from "./oktaConfig";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";
import RequiredAuth from "./components/security/SecureRoute";
import SetupHttpClient from "./components/security/SetupHttpClient";
import Login from "./views/Account/Login";
import TwoFactor from "./views/Account/TwoFactor";
import Organisation from "./views/Organisation/Organisation";
import ManageUsers from "./views/Users/ManageUsers";
import CreateUser from "./views/Users/CreateUser";
import GenericError from "./views/Error/GenericError";
import EditOrganisation from "./views/Organisation/EditOrganisation";
import GhqLodgement from "./views/GhqLodgement/GhqLodgement";
import { RequiredGhqAuth } from "./components/security/secure.ghqroute";
import HomeComponent from "./views/homepage";

const oktaAuth = new OktaAuth(oktaConfig);

export const RoutingModule = () => {
  const navigate = useNavigate();
  const originalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={originalUri}>
      <SetupHttpClient>
        <Routes>
          <Route path="/" element={<HomeComponent />} />

          <Route element={<RequiredGhqAuth />}>
            <Route path="/ghq/lodgement" element={<GhqLodgement />} />
          </Route>

          <Route element={<RequiredAuth />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/lodgement" element={<Lodgement />} />
            <Route path="/lodgement/:lodgementId" element={<Lodgement />} />
            <Route path="/manageUsers" element={<ManageUsers />} />
            <Route path="/createUser" element={<CreateUser />} />
            <Route path="/createUser/:userId" element={<CreateUser />} />
            <Route
              path="/organisation/:organisationId"
              element={<EditOrganisation />}
            />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/mfa" element={<TwoFactor />} />
          <Route path="/registerOrganisation" element={<Organisation />} />
          <Route path="/login/callback" element={<LoginCallback />} />
          <Route path="/error" element={<SubmitStatus status="failure" />} />
          <Route path="/err" element={<GhqSubmitStatus status="failure" />} />
          <Route
            path="/registerOrganisationError"
            element={
              <GenericError
                primaryHeading="Something went Wrong"
                primaryAction="Please try again later"
                secondaryAction="If the problem persists please contact us at acphelp@tal.com.au"
                actionButtonName="BACK TO LOGIN"
                actionButtonUrl="/login"
              />
            }
          />
          <Route
            path="/registerUserError"
            element={
              <GenericError
                primaryHeading="Something went Wrong"
                primaryAction="Please try again later"
                secondaryAction="If the problem persists please contact us at acphelp@tal.com.au"
                actionButtonName="BACK TO MANAGE USERS"
                actionButtonUrl="/manageUsers"
              />
            }
          />
          <Route
            path="/addBranchError"
            element={
              <GenericError
                primaryHeading="Something went Wrong"
                primaryAction="Please try again later"
                secondaryAction="If the problem persists please contact us at acphelp@tal.com.au"
                actionButtonName="BACK TO MANAGE USERS"
                actionButtonUrl="/manageUsers"
              />
            }
          />
          <Route
            path="/invalidDomainForUserError"
            element={
              <GenericError
                primaryHeading="This email domain is not allowed for this organisation"
                primaryAction="Please try again later"
                secondaryAction="If the problem persists please contact us at acphelp@tal.com.au"
                actionButtonName="BACK TO MANAGE USERS"
                actionButtonUrl="/manageUsers"
              />
            }
          />

          <Route
            path="/duplicateRegisterationError"
            element={
              <GenericError
                primaryHeading="This organisation already exists in our systems."
                primaryAction="Please review your submission or contact support if you believe this is an error"
                secondaryAction="If the problem persists please contact us at acphelp@tal.com.au"
                actionButtonName="BACK TO LOGIN"
                actionButtonUrl="/login"
              />
            }
          />
          <Route
            path="/duplicateUserAccessController"
            element={
              <GenericError
                primaryHeading="This user already exists in our systems."
                primaryAction="Please review your submission or contact support if you believe this is an error"
                secondaryAction="If the problem persists please contact us at acphelp@tal.com.au"
                actionButtonName="BACK TO LOGIN"
                actionButtonUrl="/login"
              />
            }
          />
          <Route
            path="/duplicateUser"
            element={
              <GenericError
                primaryHeading="This user already exists in our systems."
                primaryAction="Please review your submission or contact support if you believe this is an error"
                secondaryAction="If the problem persists please contact us at acphelp@tal.com.au"
                actionButtonName="BACK TO MANAGE USER"
                actionButtonUrl="/manageUsers"
              />
            }
          />
          <Route
            path="/duplicateBranch"
            element={
              <GenericError
                primaryHeading="This branch already exists for the organisation."
                primaryAction="Please review your submission or contact support if you believe this is an error"
                secondaryAction="If the problem persists please contact us at acphelp@tal.com.au"
                actionButtonName="BACK TO MANAGE USER"
                actionButtonUrl="/manageUsers"
              />
            }
          />
          <Route
            path="/notAllowed"
            element={
              <GenericError
                primaryHeading="You are not allowed to view this page"
                primaryAction="Please review your submission or contact support if you believe this is an error"
                secondaryAction="If the problem persists please contact us at acphelp@tal.com.au"
                actionButtonName="BACK TO DASHBOARD"
                actionButtonUrl="/"
              />
            }
          />
        </Routes>
      </SetupHttpClient>
    </Security>
  );
};
