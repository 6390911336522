import styled from "@emotion/styled";
import { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { getGelTokens } from "@tal-gel/theming";
import { useNavigate } from "react-router-dom";

const Header = () => {

  return (
    <>
      <StyledHeaderContainer>
        <StyledImageContainer>
          <img
            aria-label="TAL-Logo"
                      src="/logo-primary.svg"
          ></img>
        </StyledImageContainer>
      </StyledHeaderContainer>
    </>
  );
};

const StyledHeaderContainer = styled.div`
  ${() =>
    `
    background-color: ${getGelTokens().global.themeColorBackgroundDefault};
    min-height: ${getGelTokens().global.sizeBaseX18}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${getGelTokens().global.themeColorBorderDefault};
  `}
`;

const StyledImageContainer = styled.div`
  ${() =>
    `
    margin-left:  ${getGelTokens().global.sizeBaseX8}px;
  `}
`;

export default Header;
